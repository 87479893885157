.dlm-active-flag {
    // background-color: green;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.dlm-active-flag i {
    margin: 5px;
    font-size: small;
}