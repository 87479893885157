@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

body {
  /* font-family: 'Lato', sans-serif; */
  box-sizing: border-box;
  /* padding-top: 75px; */
}

* {
  outline: none;
}

.slider-button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  border: none;
  background: #FFD800;
  border-radius: 30px;
  /* text-transform: uppercase; */
  box-sizing: border-box;
  padding: 10px 30px;
  font-weight: 400;
  font-size: 13px;
  cursor: cursor;
  /* position: relative; */
  /* top: 10%; */
  /* left: 40%; */
}

.slider-button-1 {
  top: 400%;
  left: 45%
}

.slider-button-4 {
  top: 450%;
  left: 53%
}

.slider-button-5 {
  top: 450%;
  left: 46%
}

.slider-button-6 {
  top: 450%
}

.slider-button-9 {
  top: 400%;
  left: 45%
}

.slider-button:hover {
  color: #FFFFFF;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  height: 52.5vh;
  overflow: hidden;
  font-family: Gabriela, Arial, Helvetica, sans-serif;

}

.slide {
  max-height: 52.5vh;
  background-size: contain !important;
  /* background-size: cover !important; */
  /* background-position-x: right !important; */
  font-family: Gabriela, Arial, Helvetica, sans-serif;
  /* background-position: 0% 100%; */
}

@media (max-width:1025px) {
  .slider-wrapper {
    position: relative;
    height: 48vh;
    overflow: hidden;
    font-family: Gabriela, Arial, Helvetica, sans-serif;

  }

  .slide {
    max-height: 48vh;
    background-size: contain !important;
    font-family: Gabriela, Arial, Helvetica, sans-serif;

  }

}

.slide::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9))); */
  /* background: linear-gradient(transparent, rgba(0, 0, 0, 0.9)); */
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url('/src/assets/images/arrow.png') no-repeat center center / 32px;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  background: url('/src/assets/images/arrow.png') no-repeat center center / 32px;
  cursor: pointer;

}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -1px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -1px;
}

.slider-content {
  text-align: center;
  font-family: Gabriela, Arial, Helvetica, sans-serif;

}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 30%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: Gabriela, Arial, Helvetica, sans-serif;
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  font-family: Gabriela, Arial, Helvetica, sans-serif;

}

.slider-content .inner div h1 {
  font-weight: 700;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 2.25rem;
  line-height: 1;
  font-family: Gabriela, Arial, Helvetica, sans-serif;

}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0.5rem auto;
  max-width: 640px;
  font-family: Gabriela, Arial, Helvetica, sans-serif;

}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-family: Arial, Helvetica, sans-serif;

}

.slider-content section span {
  color: #FFFFFF;
  font-family: Gabriela, Arial, Helvetica, sans-serif;

}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
  font-family: Gabriela, Arial, Helvetica, sans-serif;

}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;

}

.slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
  font-family: Gabriela, Arial, Helvetica, sans-serif;

}

@media (max-height: 500px) {

  .slider-wrapper,
  .slide {
    height: calc(60vh - 75px);
  }
}

@media (max-width: 640px) {

  .slider-wrapper,
  .slide {
    height: calc(60vh - 75px);
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (min-width:1200px) {
  .slider-list li {
    font-size: 1.25rem !important;
    margin: 2rem auto !important;
  }
}


.slider-list {
  margin: auto;
}

.slider-list li {
  color: #FFFFFF;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0.5rem auto;
  max-width: 640px;
  text-align: justify;
}

.dark-title {
  color: #4970d8 !important;
}

.slider-sub-heading {
  position: absolute;
  left: 33%;
  width: 630px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
}

.slider-sub-heading-1 {
  position: absolute;
  left: 39%;
  width: 630px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
  color: white
}

.slider-sub-heading-2 {
  position: absolute;
  left: 28%;
  width: 860px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
  color: #182547;
  /* background-color: #FFD800; */
}

.slider-sub-heading-3 {
  position: absolute;
  left: 33%;
  width: 630px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
}

.slider-sub-heading-4 {
  position: absolute;
  left: 40%;
  width: 760px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
  color: white
}

.slider-sub-heading-5 {
  position: absolute;
  left: 31.5%;
  width: 850px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
  color: #182547;
  /* background-color: #FFD800; */
  /* color: white */
}

.slider-sub-heading-6 {
  position: absolute;
  left: 29.5%;
  width: 820px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
  color: #182547;

}

.slider-sub-heading-7 {
  position: absolute;
  left: 33%;
  width: 630px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
}

.slider-sub-heading-8 {
  position: absolute;
  left: 33%;
  width: 630px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
}

.slider-sub-heading-9 {
  position: absolute;
  left: 33%;
  width: 630px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
  color: white;
  /* background-color: red */
}

.slider-sub-heading-10 {
  position: absolute;
  left: 33%;
  width: 630px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
}

.slider-sub-heading-11 {
  position: absolute;
  left: 33%;
  width: 630px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
}

.slider-sub-heading-12 {
  position: absolute;
  left: 33%;
  width: 630px;
  /* right: ; */
  margin: auto;
  top: 100%;
  font-size: 1.5rem;
}

@keyframes tabImg {
  0% {
    background-image: url('/src/assets/images/B4_a.png');
  }

  /* 20% {
    background-image: url('frame-02.png');
  }

  40% {
    background-image: url('frame-03.png');
  }

  60% {
    background-image: url('frame-04.png');
  }

  80% {
    background-image: url('frame-05.png');
  } */

  100% {
    background-image: url('/src/assets/images/B4_b.png');
  }
}


@keyframes moblieImg {
  0% {
    background-image: url('/src/assets/images/B6.png');
  }

  /* 20% {
    background-image: url('frame-02.png');
  }

  40% {
    background-image: url('frame-03.png');
  }

  60% {
    background-image: url('frame-04.png');
  }

  80% {
    background-image: url('frame-05.png');
  } */

  100% {
    background-image: url('/src/assets/images/B6_b.png');
  }
}


.dark-heading {
  color: #2448A2 !important;
  font-size: 2.5rem !important;
}

.dark-description {
  color: #2448A2 !important;
  font-size: 1.5rem !important
}

.light-heading {
  /* color: #2448A2 !important; */
  font-size: 2.5rem !important;
}

.light-description {
  /* color: #2448A2 !important; */
  font-size: 1.5rem !important
}