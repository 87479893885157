$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 410px;
$assetPath :"/assets/images";

// ANT DESIGN

.ant-message .anticon {
  display: inline-flex;
}

// .search-tabs-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
//   // padding: 3px;
//   // background: #fff;
// }

.search-tabs-container>.ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

.search-services-container .ant-select-selector {
  border-radius: 50px !important;
  text-align: center;
}

.card-action-btn {
  padding: 1rem;
  margin: 1rem;
}

.paginition-container .anticon {
  display: inline-flex !important;
}

.horizontal-radio .ant-radio-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
}

.all-posts-ques {
  white-space: normal;
}

// bOOTSTRAP 

.table-pa td,
.table-pa th {
  padding: .5rem !important;
  vertical-align: middle !important;
}

/* Paytm Payemnt */

// #paytm-checkoutjs.ptm-own-element .paytm-wrapper {}

#paytm-app .ptm-payment-wrapper {
  max-width: 510px !important;
}

@media (min-width: 1200px) {
  #paytm-app .ptm-payment-wrapper {
    max-width: 510px !important;
  }
}

@media screen and (min-width: 768px) {

  #paytm-checkoutjs .ptm-own-element .offer-desktop-view,
  #paytm-checkoutjs.ptm-own-element .paytm-wrapper {
    max-width: 510px !important;
  }
}

// -----------------------------------------------------------

#root {
  overflow-x: hidden;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
  /* this is optional and should be different for every project */
}

.sticky-wrapper {
  position: relative;
  height: 3rem;
  /* We need to change this value */
}

.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.box {
  margin-bottom: 0px;
}

.box2 {
  margin-left: 50px;
  margin-right: 50px;
}


.header-strip {
  background-color: #4970D8;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  justify-content: space-around;
}

.strip-left-actions {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  // width: 30rem;
}

.strip-right-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // width: 25rem;
}

.strip-phone {
  padding: 0.5rem;
  white-space: nowrap;
  color: #f1f1f1;
}

.strip-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  font-size: larger;
  color: white;
  width: 150px;
  margin: auto 2rem;
}

.strip-make-appointment {
  display: flex;
}

.section-heading {
  font-weight: 600;
  font-size: 1.5rem;
  white-space: normal
}

.heading {
  font-family: Montserrat, sans-serif;
  margin: 3em 3em 3em 1em;
}

.heading-home {
  font-family: Montserrat, sans-serif;
  text-align: center;
  color: #474747;
  margin: 2em;
}

.offer-card {
  padding: 0.75rem;
  text-align: center;
  background-color: #FCDEFA;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.offer-card:hover {
  filter: drop-shadow(2px 2px 4px grey);
}

.offers_icon {
  max-width: 25px;
  margin-right: 2px;
}

.normal {
  color: #5d5d5d;
  //width: 34em;
  //text-align: center;
  //margin-left: 5em;
}

.title {
  color: white;
  font-size: 1.3em;
  font-family: Nunito, sans-serif;
  margin: 1.8em 0 0 2.9em;
}

.input {
  border-width: 1px;
  border-color: #AEAEAE;
  border-radius: 0;
}

.custom-link {
  font-weight: 700;
  white-space: nowrap;
}

.custom-link:hover {
  color: #007bff !important;
}

.card-img {
  @media (min-width: 1200px) {
    height: 100vh;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
}




.carousel-card {
  // padding: 1rem;
  font-size: 1rem !important;
  margin: 0px !important;
  // background-color: #000000 !important;
  // min-height: 70vh !important;
  max-height: 70vh !important;
  width: 100%;
}

.car-img {
  width: 100%;
  max-height: 70vh;
}




.carousel-1 {
  max-height: 285px;
  background: rgb(212, 128, 212);
  background: -moz-linear-gradient(90deg, rgba(212, 128, 212, 1) 0%, rgba(193, 131, 191, 1) 58%, rgba(199, 171, 198, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(212, 128, 212, 1) 0%, rgba(193, 131, 191, 1) 58%, rgba(199, 171, 198, 1) 100%);
  background: linear-gradient(90deg, rgba(212, 128, 212, 1) 0%, rgba(193, 131, 191, 1) 58%, rgba(199, 171, 198, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d480d4", endColorstr="#c7abc6", GradientType=1);
}

.carousel-2 {
  max-height: 285px;
  background: rgb(103, 129, 168);
  background: -moz-linear-gradient(90deg, rgba(103, 129, 168, 1) 0%, rgba(191, 208, 217, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(103, 129, 168, 1) 0%, rgba(191, 208, 217, 1) 100%);
  background: linear-gradient(90deg, rgba(103, 129, 168, 1) 0%, rgba(191, 208, 217, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6781a8", endColorstr="#bfd0d9", GradientType=1);
}

.carousel-3 {
  max-height: 285px;
  background: rgb(103, 213, 217);
  background: -moz-linear-gradient(90deg, rgba(103, 213, 217, 1) 0%, rgba(150, 186, 187, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(103, 213, 217, 1) 0%, rgba(150, 186, 187, 1) 100%);
  background: linear-gradient(90deg, rgba(103, 213, 217, 1) 0%, rgba(150, 186, 187, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#67d5d9", endColorstr="#96babb", GradientType=1);
}

.carousel-4 {
  color: black;
  max-height: 285px;
  background: rgb(255, 255, 255) !important;
  // background: -moz-linear-gradient(90deg, rgb(194, 194, 194) 0%, rgb(255, 255, 255) 100%);
  // background: -webkit-linear-gradient(90deg, rgb(204, 204, 204) 0%, rgb(255, 255, 255) 100%);
  // background: linear-gradient(90deg, rgb(119, 119, 119) 0%, rgb(255, 255, 255) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#67d5d9", endColorstr="#96babb", GradientType=1);
}

#mental_health {
  margin: 0 !important;
  // background-color: red;
}

.carousel-imgs {
  margin-top: 10px;
  min-width: 200px;
  min-height: 250px;
  max-height: 300px;
  padding: 0.5rem 0rem;

  // @media (min-width: $xl) {
  //   margin-right: 90px;
  // }

  // @media (max-width: $xl - 1) and (min-width: $lg) {
  //   margin-right: 50px;
  // }

  // @media (max-width: $lg - 1) and (min-width: $md) {
  //   margin-right: 40px;
  //   width: 30%;
  // }

  // @media (max-width: $md - 1) and (min-width: $sm) {
  //   margin-right: 10px;
  //   width: 30%;
  // }

  // @media (max-width: $sm - 1) {
  //   margin-right: 20px;
  //   width: 20%;
  // }

}

.car-container-mob {
  position: relative;
  margin-top: 40px;
}

.left_arrow {
  top: 40% !important;
  // background-color: #ff0000e5 !important;
}

.right_arrow {
  top: 40% !important;
  // background-color: #ff0606f5;
}

// .arrow-left_icon {
//   display: inline-block;
//   width: 25px;
//   height: 25px;
//   // background-image: url(#{$assetPath}/arrow.png);
//   background-image: url(/assets/images/arrow.png);
//   background-position: -185px -60px;
//   align-self: center;
// }

// .arrow-right_icon {
//   display: inline-block;
//   width: 25px;
//   height: 25px;
//   // background-image: url(#{$assetPath}/arrow.png);
//   background-position: -148px -60px;
//   align-self: center;
// }

.dummy-arrow {
  filter: blur(10px);
  background: #007bff30;
  position: absolute;
  top: 3px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  /* margin: 70% 0px; */
  margin: auto;
  z-index: -1;
}



.card-title-1 {
  font-weight: 700;
  font-family: Comfortaa, sans-serif;
  color: #fdfffa;
  width: 100%;

  //margin-top: 20px;
  //margin-right: 50px;
  @media (min-width: $xl) {
    margin-left: 100px;
    font-size: x-large;
  }

  @media (max-width: $xl - 1) and (min-width: $md) {
    //text-align: center;
    margin-left: 60px;
    font-size: x-large;
    //margin: 30px 10px 10px 60px;
  }

  @media (max-width: $md - 1) and (min-width: $sm) {
    text-align: center;
    //margin-left: 40px;
    font-size: large;
  }

  @media (max-width: $sm - 1) {
    margin-top: 10px;
    text-align: center;
    //margin-left: 20px;
    font-size: smaller;
  }
}

.card-text-1 {
  font-family: "Work Sans", sans-serif;
  color: #fdfffa;
  margin-left: 40px;
  line-height: 28px;

  @media (min-width: $xl) {
    margin-top: 20px;
    width: 60%;
    font-size: 1.2em;
  }

  @media (max-width: $xl - 1) and (min-width: $lg) {
    width: 85%;
    font-size: 1.2em;
  }

  @media (max-width: $lg - 1) and (min-width: $md) {
    width: 88%;
    font-size: 1.12em;
  }

  @media (max-width: $md - 1) and (min-width: $sm) {
    width: 99%;
    margin-left: 10px;
    font-size: 0.9em;
  }

  @media (max-width: $sm - 1) {
    width: 98%;
    line-height: 15px;
    margin-left: 10px;
    padding-bottom: 10px;
    font-size: 0.6em;
  }
}

.card-title-2 {
  font-weight: 700;
  font-family: Comfortaa, sans-serif;
  color: #fdfffa;
  width: 70%;

  @media (min-width: $xl) {
    margin-left: 40px;
    font-size: x-large;
  }

  @media (max-width: $xl - 1) and (min-width: $lg) {
    width: 100%;
    margin-left: 40px;
    font-size: x-large;
  }

  @media (max-width: $lg - 1) and (min-width: $md) {
    width: 100%;
    margin-left: 20px;
    font-size: x-large;
  }

  @media (max-width: $md - 1) and (min-width: $sm) {
    //text-align: center;
    margin-left: 10px;
    width: 100%;
    font-size: large;
  }

  @media (max-width: $sm - 1) {
    margin-top: 10px;
    margin-left: 20px;
    width: 100%;
    font-size: smaller;
  }
}

.card-text-2 {
  color: #707f89;
  font-family: Kanit, sans-serif;

  @media (min-width: $xl) {
    margin-top: 20px;
    width: 30%;
    font-size: 1.3em;
  }

  @media (max-width: $xl - 1) {
    text-align: center;
    margin: 1px;
  }
}

.card-title-3 {
  font-weight: 700;
  font-family: Comfortaa, sans-serif;
  color: #fdfffa;
  width: 70%;

  @media (min-width: $xl) {
    margin-left: 40px;
    font-size: x-large;
  }

  @media (max-width: $xl - 1) and (min-width: $lg) {
    width: 100%;
    margin-left: 40px;
    font-size: x-large;
  }

  @media (max-width: $lg - 1) and (min-width: $md) {
    width: 100%;
    margin-left: 20px;
    font-size: x-large;
  }

  @media (max-width: $md - 1) and (min-width: $sm) {
    //text-align: center;
    margin-left: 10px;
    width: 100%;
    font-size: large;
  }

  @media (max-width: $sm - 1) {
    margin-top: 10px;
    margin-left: 20px;
    width: 100%;
    font-size: smaller;
  }
}

.card-text-3 {
  color: #707f89;
  font-family: Kanit, sans-serif;

  @media (min-width: $xl) {
    margin-top: 20px;
    width: 30%;
    font-size: 1.3em;
  }

  @media (max-width: $xl - 1) {
    text-align: center;
    margin: 1px;
  }
}

.card-title-3 {
  font-weight: bold;
  font-family: Montserrat, sans-serif;

  @media (min-width: $xl) {
    margin-left: 40px;
    margin-top: 60px;
    color: #565957;
    font-size: xx-large;
  }

  @media (max-width: $xl - 1) {
    text-align: center;
    margin: 10px 5px 5px 5px;
    color: dimgrey;
    font-size: large;
  }
}

.card-text-3 {
  font-family: Kanit, sans-serif;
  color: #7c817c;

  @media (min-width: $xl) {
    margin-left: 40px;
    margin-top: 40px;
    width: 30%;
    font-size: 1.3em;
  }

  @media (max-width: $xl - 1) {
    text-align: center;
    margin: 5px;
  }
}

.small-carousel {
  @media (max-width: $lg) and (min-width: $md) {
    height: 150px;
  }
}

.buttons {
  color: dimgrey;
  margin-left: 67px;
}

.sub-title {
  color: grey;
  font-size: 15px;
}

.sub-title-2 {
  color: grey;
  font-size: 15px;
  width: 50px;
  height: 80px;
}

.card-width {
  @media (min-width: $xl) {
    width: 100%;
    height: 100%
  }

  @media (max-width: $xl - 1) and (min-width: $lg) {
    width: 60%;
  }
}

.badge2 {
  font-family: Montserrat, sans-serif;
}

.sub-title-3 {
  color: grey;
  font-size: 13px;
  //height: 30px;
  font-family: Montserrat, sans-serif;
}

.card-doc-title {
  font-family: Montserrat, sans-serif;
  font-size: 1.5em;
  color: #343434;
  height: 30px;
}

.line-spacing {
  line-height: 40px;
}

.line-spacing-2 {
  line-height: 25px;
}

// .tags-container {
//   // background-color: white;
// }

.tags-container:hover {
  background-color: #f1f1f1;
  filter: drop-shadow(2px 2px 5px #e0e0e0);
}

.ts-cards-container {
  overflow-x: scroll;
}

/* width */
.ts-cards-container::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.ts-cards-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.ts-cards-container::-webkit-scrollbar-thumb {
  background: rgb(182, 182, 182);
  border-radius: 10px;
  width: 50px !important;
}

/* Handle on hover */
.ts-cards-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.questions-container {
  max-height: 87vh;
  overflow-y: scroll;
}

.site-card-wrapper {
  // background-color: #343434;
  min-height: 400px;
  display: flex;
  width: 100%;
  align-items: center;
  margin: auto;
}

.contact-card {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  width: 100%;
  // align-items: center;
  margin: auto;
  padding: 1rem;

}

.dark-dots {
  padding: 1rem;
  // background-color: #343434;
}

.dark-dots li {
  color: #343434;
}

.dark-dots li button {
  color: #000000 !important;
  background-color: #000000 !important;
}

.primary-dots {
  padding: 1rem;
  // background-color: #343434;
}

.primary-dots li {
  color: #00c3ff;
}

.primary-dots li button {
  color: #002d52 !important;
  background-color: #00398f !important;
}

.slider-tickler-container {
  position: absolute;
  bottom: 0px;
  background-color: #3067ff;
  width: 100vw;
  padding: 0.5rem;
  overflow: hidden;
  // height: 10px;
}

.slider-tickler {
  // position: relative;
  // bottom: 0px;
  // display: flex;
  // justify-content: space-between;
  width: 100%;
  height: 100%;

  text-align: center;
  color: white;
  // background-color: #00c3ff;

  // transform: translateX(100%);
  font-size: larger;
  letter-spacing: 3px;
  // animationName: "scroll-left",
  // animationDuration: "4s",
  // animationIterationCount: "infinite",
  // animation: scroll-left-tickler 25s linear infinite;
  // animation-direction: alternate;   
}

.slider-tickler:hover {
  background-color: #141F3B10;
  // animation: none;
  animation-play-state: paused;
}

.slider-tickler-container-mobile {
  position: relative;
  background-color: #001d6e;
  width: 100%;
  height: 40px;
  padding: 0.5rem;
  overflow: hidden;
}

.slider-tickler-mobile {
  position: absolute;
  // width: 100%;
  color: white;
  // background-color: #00c3ff;

  transform: translateX(100%);
  font-size: larger;
  letter-spacing: 3px;
  animation: scroll-left-tickler 20s linear infinite;
}

.slider-tickler-mobile:hover {
  background-color: #141F3B;
  // animation: none;
  animation-play-state: paused;
}


.hashtag-strip-container {
  // background-color: #141F3B20;
  position: relative;
  width: 100%;
  height: 50px;
  padding: 0px;
}

.hashtag-strip {
  // background-color: #141F3B10;
  // background-color: #F8F9FA !important;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  // width: 100%;
  animation: scroll-left-tickler 30s linear infinite;
  // animation-direction: alternate;   
}

.header-strip-icons {
  margin-right: 0.5rem;
}

.hashtag-strip:hover {
  background-color: #141F3B10;
  // animation: none;
  animation-play-state: paused;
}

@keyframes scroll-left-tickler {
  0% {
    // right: 0;
    // left: 100%;
    transform: translateX(100%);
  }

  100% {
    // left: -120vw;
    // left: -50%;
    transform: translateX(-100%);

    // right: 100%;
  }

  // 100% {
  //   left: 0;
  // }
}

@keyframes scroll-left {
  0% {
    // right: 0;
    left: 100%;
  }

  100% {
    // left: -120vw;
    left: -100%;
    // right: 100%;
  }

  // 100% {
  //   left: 0;
  // }
}


@keyframes highlight {
  0% {
    filter: drop-shadow(2px 2px 5px yellow);
  }

  50% {
    filter: drop-shadow(8px 8px 20px yellow);
  }

  100% {
    filter: drop-shadow(2px 2px 5px yellow);
  }

}

.search-service-box {
  border-radius: 10px;
  // border: 1px solid #000000;
  filter: drop-shadow(2px 2px 20px #bdbdbd);
}

.search-services-container {
  padding: 1rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  // background-color: #007bff;
}

.search-search-title {
  padding: 1rem 4rem !important;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;

}


@media (max-width:1025px) {
  // .search-services-container {
  // padding: 0 0.15rem 0.1rem 0.025rem;
  // }

  .search-search-title {
    // padding: 0.275rem 2rem;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .search-btn {
    // padding: 5px 30px;
    font-size: 1rem !important;
  }
}


.why-choose-us {
  background-image: linear-gradient(to right, #1890ff, #e6f7ff);
  color: #002d52 !important;
}

.sub-heading {
  text-align: center;
  margin: 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: #858585;
}

.text-status {
  text-align: center;
  font-size: 1.75rem;
  font-weight: 500;
  color: #858585;
  text-transform: uppercase;
}

.step-done {
  color: var(--success) !important;
}


.disc-action-item {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  // flex-wrap: wrap;
  margin: 1rem 0;
}

.disc-label {
  font-size: 1.1rem;
  color: #757575;
  margin-right: 0.2rem;
}

.lateby-input {
  display: inline-flex;
  font-size: 1.1rem !important;
  width: 100px !important;
  border-bottom: 1px solid grey !important;
  margin: 0 0.25rem !important;
}

.date-input-label {
  font-size: 1.1rem;
  color: #757575;
  margin-right: 0.2rem;
}