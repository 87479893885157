.label {
  color: #777;
  font-family: "Montserrat", "Open Sans", Arial, sans-serif;
  font-size: 14px;
}

.input {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  font-size: 14px;
}

.form-head {
  font-family: "Kanit", sans-serif;
  color: #474747;
  font-size: xx-large;
}