/* Paytm Payemnt */

@media screes and (min-width: 768px) {

    #paytm-checkoutjs .ptm-own-element .offer-desktop-view,
    #paytm-checkoutjs.ptm-own-element .paytm-wrapper {
        max-width: 510px !important;
    }
}


.fc {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.fc>* {
    flex: 1 1;
}

.heading {
    font-family: 'Montserrat', sans-serif;
    color: #474747;
    font-size: 1.9em;
    font-weight: 500;
}

.normal {
    font-family: 'Nunito', sans-serif;
    font-size: 1.1rem;
    margin: 1rem 0;
}

.normal-text-color {
    color: #343434;
}

.link:hover {
    text-decoration: none;
}

.head-xl {
    font-family: "Oxygen", "Segoe UI", "Roboto", sans-serif;
}

.head-md {
    font-family: "Montserrat", sans-serif;
    color: #474747;
}

.head-sm {
    color: #474747;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
}

.light-text {
    color: #474747;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
}

.btn-label {
    font-family: "Kanit", sans-serif;
    font-weight: 800;
}

.unstyled-link,
.unstyled-link:link,
.unstyled-link:hover {
    color: white;
    text-decoration: none;
}

.nav-unstyled-link,
.nav-unstyled-link:link,
.nav-unstyled-link:hover {
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
}

.btn-text {
    font-family: Nunito, sans-serif;
    font-weight: 600;
}