$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 410px;
$xxs: 310px;

.app-img {
  @media (min-width: $xl) {
    height: 100vh;
  }

  @media (max-width: $xl - 1) and (min-width: $md) {
    height: 160vh;
  }

  @media (max-width: $md - 1) and (min-width: $sm) {
    height: 150vh;
  }

  @media (max-width: $sm - 1) {
    height: 140vh;
  }
}

.app-invite-small {
  // background-image: url(\images\app\mobile.png);
  // background-image: url(/images/app/mobile.png);
}