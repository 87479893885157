// XL = 1200px
// SM = 576px
// MD = 768
// LG = 992

$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 410px;

.prev-head {
  font-family: "Kanit", sans-serif;
  color: #474747;

  @media (min-width: $xs) {
    font-size: 4em;
  }

  @media (max-width: $xs - 1) {
    font-size: 3em;
  }
}

.prev-text {
  font-size: 1.2em;
  font-family: "Noto Sans", sans-serif;
  color: #383d41;
}

.prev-head-x {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 1.6em;
}

.prev-div {
  margin: 5px;
  padding: 5px;
  background: #2E69C9;
  border-radius: 5px;
}

.prev-div div {
  padding: 20px;
}

.prev-text-x {
  font-family: "Montserrat", sans-serif;
  //color: white;
  font-size: 1.1em;
}

.carousel-img {
  @media (min-width: $xl) {
    height: 70vh;
  }

  @media (min-width: $md) and (max-width: $xl - 1) {
    height: 70vh
  }
}

.carousel-text-1 {
  font-family: Kanit, sans-serif;
  color: #38393a;

  @media (min-width: $xl) {
    width: 60%;
    margin-top: 20vh;
    font-size: x-large;
  }

  @media (max-width: $xl - 1) and (min-width: $md) {
    width: 60%;
    margin-top: 22vh;
    font-size: larger;
  }

  @media (max-width: $md - 1) and (min-width: $sm) {
    width: 60%;
    margin-top: 12vh;
    font-size: 1em;
  }

  @media (max-width: $sm - 1) and (min-width: $xs) {
    width: 70%;
    margin-top: 8vh;
    font-size: 0.8em;
  }

  @media (max-width: $xs - 1) {
    width: 80%;
    margin-top: 5vh;
    font-size: 0.6em;
  }
}

.carousel-text-2 {
  font-family: Kanit, sans-serif;
  color: #535761;
  float: left;
  width: 40%;
  margin: auto;

  @media (min-width: $xl) {
    font-size: xx-large;
  }

  @media (max-width: $xl - 1) and (min-width: $md) {
    font-size: x-large;
  }

  @media (max-width: $md - 1) and (min-width: $xs) {
    font-size: large;
  }

  @media (max-width: $xs - 1) {
    width: 55%;
    font-size: 0.6em;
  }
}

.carousel-text-3 {
  margin: auto;
  font-family: Kanit, sans-serif;
  color: #8b3f3c;
  float: right;

  @media (min-width: $xl) {
    width: 25%;
    font-size: x-large;
  }

  @media (max-width: $xl - 1) and (min-width: $md) {
    width: 25%;
    font-size: 1.4em;
  }

  @media (max-width: $md - 1) and (min-width: $sm) {
    width: 25%;
    font-size: 1em;
  }

  @media (max-width: $sm - 1) and (min-width: $xs) {
    width: 40%;
    font-size: 0.9em;
  }

  @media (max-width: $xs - 1) {
    width: 50%;
    font-size: 0.6em;
  }
}