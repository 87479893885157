.profile-head {
  //margin: 20px;
  padding: 35px 20px 20px 20px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 2.2em;
  color: white;
}

.profile-head-md {
  font-family: Montserrat, sans-serif;
  font-size: large;
}

.profile-text {
  font-family: Nunito, sans-serif;
}

.table {
  margin-left: -15px;
}