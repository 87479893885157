// SM - 576px
// MD - 768px
// LG - 992px
// XL - 1200px

.service-card-img {
  @media (min-width: 1200px) {
    width: 100%;
    max-height: 220px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    height: 20vw;
  }
}

.service-card-title {
  @media (min-width: 1200px) {
    font-size: 1.3em;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    font-size: 0.9em;
  }
}

.service-card-subtitle {
  @media (min-width: 1200px) {
    font-size: 0.8em;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    font-size: 0.7em;
    display: block;
    height: 30px;
  }
}