$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 410px;
$xxs: 310px;

.img-visibility {
  @media (min-width: $xl) {
    height: 110vh;
  }

  @media (max-width: $xl - 1) and (min-width: $sm) {
    visibility: hidden;
    height: 170vh;
  }

  @media (max-width: $sm - 1) and (min-width: $xxs) {
    visibility: hidden;
    height: 160vh;
  }

  @media (max-width: $xxs - 1) {
    visibility: hidden;
    height: 170vh;
  }
}

.img-overlay {
  background-color: rgba(50, 105, 193, 0.7);
  height: 100%;
}

.about-heading {
  font-family: 'Montserrat', sans-serif;
  color: #474747;
  font-size: 1.9em;
  font-weight: 500;
}

.top-heading {
  font-family: 'Montserrat', sans-serif;
  color: #474747;
  font-size: 1.9em;
  font-weight: 500;
  margin-top: 30px;
}

// .incubators_container {
//   // background-color: aqua;
// }

.incubators_container img {
  margin: 0.5em;
}

.about-bg {
  // background: url(../images/about.png);
  // background-attachment: fixed;
  // background-size: cover;
}

.blue-mask {
  background-color: #4b4b4b !important;
  // background: #36b5ffa4 !important;
  // background: #2b6bd190 !important;
}