/* .appointment-card {} */

.appointment-card:hover {
    filter: drop-shadow(2px 2px 5px #f1f1f1);
}

.card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
    font-family: "Oxygen", "Segoe UI", "Roboto", sans-serif;
    margin-bottom: 0.5rem;
    font-weight: 350;
}

.card-title-with-extra {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.75rem;
    font-family: "Oxygen", "Segoe UI", "Roboto", sans-serif;
    margin-bottom: 0.5rem;
    font-weight: 350;
}

@media screen and (max-width:374px) {
    .role-details-container {
        flex-direction: column
    }
}

.role-details-container {
    display: flex;
    align-items: center;


}

/* @media screen and (max-width:400px) {
    .role-details-container-small {
        flex-direction: column
    }
}

.role-details-container-small {
    display: flex;
    align-items: center;

    background-color: aqua;
} */



.patient-details-container {
    display: flex;
    align-items: center;
}

.patient-card-body {
    width: 100%;
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.patient-details {
    display: inline-block;
    flex-direction: column;
    width: fit-content;
    padding: 2%;
    font-size: larger;
}

.patient-pic {
    /* width: 10%; */
    /* position: relative; */
    background-color: #fcfcfc;
    /* max-height: 150px; */
    /* max-width: 200px !important; */
    /* overflow: hidden; */
    min-width: 100px;
    min-height: 100px;
    padding: 0px;
    margin-right: 1rem;
    text-align: center;
}

.profile-dot {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1%;
    font-size: smaller;
}

.patient-pic img {
    width: 100%;
    max-width: 100px;
    /* min-width: 120px; */
    /* max-height: 100px; */
    /* background-color: #f1f1f1; */
}

.patient-name {
    /* display: flex; */
    color: #000000;
    font-size: 1.5rem;
}

.patient-age {
    color: gray;
    font-size: 1rem;
}

.next-visit-btn {
    border-radius: 5%;
    color: #ffffff;
    display: inline-flex;
    float: right;
    padding: 1%;
    cursor: pointer;
}

.top-right-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* float: right; */
    padding: 1%
    /* cursor: pointer; */
}

.patient-card-actions-outer {
    /* background-color: blue; */
    display: block;
    flex-direction: row;
    /* align-items: baseline; */
    /* justify-content: space-evenly; */
}

.patient-card-actions {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* display: block; */
    /* background-color: blue; */
    /* display: inline-flex; */
    /* flex-direction: row; */
    /* align-items: baseline; */
    /* justify-content: space-evenly; */
}

.patient-card-action {
    /* background-color: #f5f5f5; */
    /* padding: 0px 30px; */
    margin: 0.5rem;
    /* border-radius: 5%; */
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    /* align-self: center; */
}

.patient-card-action i {
    margin: 0 0.5rem 0 2px;
    font-size: 15px;
    display: inline-flex;
}

.symptom-container {
    /* padding: 20px; */
    width: 100%;
    margin: 10px auto 0px;
    border: 1px solid #f1f1f1;
}

.symptom {
    margin: 5px 10px;
}

.symptom:hover {
    filter: drop-shadow(2px 2px 5px #f1f1f1);

}

.symptom_image {
    width: 100%;
    height: 100%;
    max-height: 250px;
    max-width: 250px;
}

.sympton-img-container {
    padding: 1%;
    max-height: 250px;
    max-width: 250px;
    overflow: hidden;
    cursor: pointer;
}


#jutsu-container div {
    width: 100% !important;
    height: 100% !important;
    min-height: 452px;
    min-width: 500px;
}

/* PRESCRIPTION FORM CSS */

@media only screen and (max-width:575px) {
    .pat-details-container {
        /* margin-top: 20px !important; */
        padding-top: 20px !important;
        border-top: 1px solid gray;
    }

    .pat-details-container table {
        margin: 0 !important;

    }

    .doc-details-container {
        border: none !important;
    }

    .doc-details-container table {
        margin: 0 !important;

    }
}


/* .upload-prescription-form {}

*/
.doc-details-container {
    /* background-color: red; */
    /* display: flex; */
    padding-bottom: 20px;
}

/* 
.pat-details-container {
    } */

/* .atr-val-container {} */

.foot-atr-val-container {
    /* background-color: aqua; */
    /* padding: 0px !important; */
    /* text-align: center; */
    font-style: italic;
    font-family: cursive;
    font-size: small;
    color: #C0C0C0;

}

.foot-attribute {
    font-weight: 700;
    padding: 0.2rem !important;
    /* text-align: right; */
}

.foot-value {
    font-weight: 300;
    padding: 0.2rem !important;
    /* text-align: left; */
}



.doc-attribute {
    font-weight: 700;
    padding: 0.5rem !important;
}

.doc-value {
    font-weight: 500;
    padding: 0.5rem !important;

}

.pat-attribute {
    font-weight: 700;
    color: #858585;
    padding: 0.5rem !important;

}

.pat-value {
    color: #858585;
    font-weight: 500;
    padding: 0.5rem !important;

}