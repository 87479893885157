.hac-container .ant-card-actions {
    border-top: 0px !important;
}

.ant-card-actions>li {
    margin: 0 !important;
}

#gift .ant-card-actions>li {
    background-color: #DC3545;
}

#pmsms .ant-card-actions>li {
    background-color: #28A745;
}

#volunteer .ant-card-actions>li {
    background-color: #007BFF;
}

.hac-container {
    max-width: 1200px;
    margin: auto;
}

.hac-main-heading {
    color: rgb(0, 0, 0);
    font-size: 2.5rem;
    text-align: center;
    padding: 2rem;
    font-family: 'Raleway';
    font-weight: bold;
    text-decoration: underline;
}

.hac-heading {
    color: aliceblue;
    font-size: 1.86rem;
    text-align: center;
    padding: 1rem 0rem;
    font-family: 'Raleway';
    /* white-space: normal; */
}

.hac-description {
    color: aliceblue;
    font-size: 1.5rem;
    /* text-align: left; */
    font-family: 'Raleway';
    padding: 0rem 0.5rem;
}

.hac-button {
    margin: 1.5rem;
    background-color: inherit;
}