@import url('https://fonts.googleapis.com/css?family=Montserrat:500,700');
@import url('https://fonts.googleapis.com/css?family=Nunito:600');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700,800|Quicksand:400,700|Kanit:400,500,700,800|Raleway:400,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Comfortaa:400,600,700|Muli:400,700,800|Nunito+Sans:400,700|Rubik:400,500,700|Titillium+Web:400,600,700|Work+Sans:400,700&display=swap');

/* ============ANT_DESING======================== */

.anticon {
    display: inline-flex !important;
}

/* ---------------------------------------- */



/*
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	src: local('Montserrat'), url("./assets/fonts/Montserrat.ttf") format('truetype');
}

@font-face {
	font-family: 'Nunito';
	font-style: normal;
	src: local('Nunito'), url("./assets/fonts/Nunito.ttf") format('truetype');
}
*/

/*
* {
	font-family: Nunito, sans-serif;
	color: #343434
}
*/

/*
.heading {
	color: #474747;
	font-family: 'Montserrat', sans-serif
}
*/

/*
.container {
	margin: 0;
	padding: 0;
	border-width: 5px;
	border-color: red;
}
*/


.row-header {
    margin: 0px auto;
    padding: 0px auto;
}

.row-content {
    margin: 0px auto;
    padding: 50px 0px 50px 0px;
    border-bottom: 1px ridge;
    min-height: 400px;
}

.footer {
    background-color: #D1C4E9;
    margin: 0px auto;
    padding: 20px 0px 20px 0px;
}

/*
.jumbotron {
	padding:70px 30px 70px 30px;
	margin:0px auto;
	background: #9575CD ;
	color:floralwhite;
}
*/

address {
    font-size: 80%;
    margin: 0px;
    color: #0f0f0f;
}

.navbar-dark {
    background-color: #512DA8;
}


.user-dash-tabs .ant-tabs-nav{
	background-color: rgba(218, 230, 255, 0.473) !important;
}
.user-dash-tabs .ant-tabs-content-holder{
	background-color: rgb(233, 235, 255) !important;
}

.highlight-hover:hover{
	/* background-color: red !important; */
	background-color: #9ec8ff50;
}
/* .hover15 figure {
	position: relative;
} */
.highlight-hover::before {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	display: block;
	content: '';
	width: 0;
	height: 0;
	background: rgba(243, 255, 78, 0.2);
	border-radius: 100%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	opacity: 0;
}
.highlight-hover:hover::before {
	-webkit-animation: circle .75s infinite;
	animation: circle .75s infinite;
	
}
@-webkit-keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 125%;
		height: 125%;
		opacity: 0;
	}
}
@keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 125%;
		height: 125%;
		opacity: 0;
	}
}