.policy-link {
    text-decoration: none;
    font-size: 1.25rem;
}

.policy-link:hover {
    text-decoration: none;

}

.policy-card-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
}

.policy-content-wrapper {
    font-size: 1.2rem;
    text-align: justify;
}

.policy-content-wrapper p {
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: 500;
    /* text-align: justify; */
}