// Book-now  

.steps-content {
    // margin-top: 16px;
    // border: 1px dashed #e9e9e9;
    border-radius: 2px;
    // background-color: #fafafa;
    min-height: 200px;
    // text-align: center;
    // padding-top: 80px;
}

.steps-action {
    margin-top: 24px;
}

@media screen and (max-width:576px) {
    .logo-card-container {
        display: flex;
        align-items: center;
        justify-content: center !important;
        flex-wrap: wrap;
        // margin: auto;
    }


    // .screen-btn-container {
    //     display: flex;
    //     align-items: center;
    //     justify-content: flex-start !important;
    //     flex-wrap: wrap;
    //     // margin: auto;
    // }

}

.screen-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    // margin: auto;
}

.logo-card-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    // margin: auto;
}

.logo-card-wrapper {
    margin: 0.8em 1em;
}

.logo-card {
    border-radius: 15px !important;
    padding-top: 10px !important;
}

.option-dependent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    // background-color: aqua !important;
}

.user-icon {
    width: 10%;
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    // flex: 0.5;
}

.user-icon img {
    width: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
}

.user-name {
    // background-color: aqua;
    width: 80%;
    display: flex;
    align-items: center;
}

.paginition-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.25rem;
    padding: 0.25rem;
}

.profile-details-container {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
}

.profile-details-container div {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    // justify-content: center;
    // width: 500px;
}

.profile-details-container span {
    width: 100px;
    font-weight: 500;
}