.loader-container-dummy {
    /* background-color: rgba(256, 256, 256, 0.01); */
    background: transparent;
    backdrop-filter: blur(2px);
    z-index: 12;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    /* max-width: 830px; */
    /* height: 100vh; */
    text-align: center;
    overflow: hidden;
    margin: auto;
}

.loader-container {
    /* /* background-image: -moz-linear-gradient(); */
    /* background-image:linear-gradient(90deg, #abbaab, #ffffff); */
    /* color:#000f64; */
    z-index: 13;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* max-width: 830px; */
    /* height: 100vh; */
    /* max-height: 800px; */
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: pink; */
    height: 100%;
}

.spinner-container {
    display: inline-block;
    text-align: center;
    /* margin: 50% auto; */
    /* position: absolute; */
    /* left: 0; */
    /* right: 0; */
    /* top: 0; */
    /* bottom: 0; */
    width: fit-content;
    height: fit-content;
    margin: auto;
    /* background-color: red; */
}

.spinner {
    text-align: center;
    margin: 0 auto;
    width: fit-content;
}

.spinnner-message {
    margin: 20% auto;
    text-align: center;
    font-size: 20px;
    font-style: italic;
    font-family: cursive;
}